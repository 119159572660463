const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://b2b-documentation.prod.creditas.io',
    gaTrackingId: null,
    trailingSlash: false,
  },
  header: {
    logo: 'https://files.readme.io/decbc75-logo-creditas.svg',
    logoLink: 'https://b2b-documentation.prod.creditas.io',
    title: 'Partner developer center',
    githubUrl: '',
    helpUrl: '',
    tweetText: '',
    social: '',
    links: [{ text: '', link: '' }],
    search: {
      enabled: false,
      indexName: '',
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
    },
  },
  sidebar: {
    forcedNavOrder: [
      '/',
      '/introduction',
      '/auto-financing'
    ],
    links: [
      { text: 'About Creditas', link: 'https://www.creditas.mx/conocenos' },
      { text: 'Aviso de privacidad', link: 'https://www.creditas.mx/legal/aviso-privacidad' },
      { text: 'Términos y condiciones', link: 'https://www.creditas.mx/legal/terminos-condiciones' },
    ],
    frontLine: false,
    ignoreIndex: false,
    title: '',
  },
  siteMetadata: {
    title: 'Creditas México Partner Developers Center',
    description: 'Apis for build credit ecosystem',
    ogImage: null,
    docsLocation: '',
    favicon: 'https://creditas.mx/favicon.svg',
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'Gatsby Gitbook Starter',
      short_name: 'GitbookStarter',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: 'src/pwa-512.png',
          sizes: `512x512`,
          type: `image/png`,
        },
      ],
    },
  },
};

module.exports = config;
